/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Darker overlay for better contrast */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff; /* White background for the modal */
  padding: 20px;
  border-radius: 12px; /* Rounded corners for a modern look */
  max-width: 500px;
  width: 100%;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #e0e0e0; /* Light background for the close button */
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  transition: background 0.3s ease; /* Smooth background transition */
}

.modal-close:hover {
  background: #d4d4d4; /* Darker background on hover */
}

.modal-content h2 {
  margin-top: 0;
  font-size: 24px;
  color: #333; /* Darker text color for the header */
}

.modal-content p {
  font-size: 16px;
  color: #666; /* Slightly lighter text color for the paragraph */
}

.modal-content a {
  color: #007bff; /* Blue color for the link */
  text-decoration: none;
  font-weight: bold;
}

.modal-content a:hover {
  text-decoration: underline; /* Underline on hover for better visibility */
}
