/* Add this to your CSS or adjust the existing styles */
.select-container {
    position: relative;
  }
  
  .select-container select {
    width: 100%;
    padding: 0.5rem 2.5rem 0.5rem 1rem; /* Add padding to account for the dropdown icon */
    appearance: none; /* Remove default dropdown icon */
    background-size: 1.5rem; /* Adjust icon size */
    border: 1px solid #ccc;
    border-radius: 0.375rem;
  }
  
  .select-container::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 0.75rem;
    height: 0.75rem;
    background-size: contain;
    transform: translateY(-50%);
  }
  